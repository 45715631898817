import { colors } from '@dentalux/ui-library-core'

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  appointmentButtonWrapper: {
    position: 'fixed',
    bottom: '12px',
    right: '64px',
    left: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '16px',
    zIndex: 1199,

    '&:hover': {
      zIndex: 1201,
    },
  },
  appointmentButton: {
    '&&&': {
      backgroundColor: colors.aqua[800],
      color: theme.palette.common.white,
      borderRadius: '16px',
      fontSize: '14px',
      padding: '16px 20px',
      textTransform: 'none',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',

      '&:hover': {
        backgroundColor: colors.aqua[900],
      },
    },
  },
  blockerButton: {
    width: '56px',
    height: '56px',
    padding: '16px',
    borderRadius: '16px',
    backgroundColor: colors.grey[0],
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',

    '&:hover': {
      backgroundColor: colors.grey[100],
    },
  },
  blockerIcon: {
    color: colors.aqua[800],
    transform: 'rotate(90deg)',
  },
}))
