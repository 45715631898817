import { AppointmentCancellation, GenderType, InsuranceType } from '@dentalux/common'
import { AppointmentDetailsLabels, BlockerLabels } from '@dentalux/ui-library-core'

import { TFunction } from 'i18next'

import { MIN_TREATMENT_DURATION } from './constants'

export const getAppointmentDetailsLabels = (t: TFunction): AppointmentDetailsLabels => ({
  deletionDialog: {
    buttons: {
      cancel: t('cancel_button'),
      save: t('delete_button'),
    },
    openAppointmentTitle: t('delete_open_appointment_confirmation'),
    title: t('delete_confirmation'),
  },
  overbooked: {
    infoText: t('appointment_overbooked.info'),
  },
  blocking: {
    infoText: t('appointment_blocking.info'),
    fieldText: t('appointment_blocking.field'),
  },
  risky: {
    infoText: t('appointment_risky.info'),
  },
  minutes: t('appointment_duration_min'),
  cancellation: Object.values(AppointmentCancellation).reduce(
    (acc, type) => ({
      ...acc,
      [type]: t(`appointment_cancel.${type}`),
    }),
    {}
  ),
  cancellationDialog: {
    alert: t('short_notice_cancellation'),
    buttons: {
      cancel: t('appointment_cancel_button'),
      save: t('cancel_appointment'),
    },
    transferToOpenAppointmentLabel: t('appointment_cancel.transfer'),
    commentLabel: t('appointment_cancel.reason_details'),
    selectLabel: t('appointment_cancel.reason'),
    title: t('appointment_cancel_confirmation'),
  },
  gender: Object.values(GenderType).reduce(
    (acc, type) => ({
      ...acc,
      [type]: t(`gender_${type}`),
    }),
    {}
  ),
  insurance: Object.values(InsuranceType).reduce(
    (acc, type) => ({
      ...acc,
      [type]: t(type.toLowerCase()),
    }),
    {}
  ),
  buttons: {
    cancel: t('appointment_edit.form.cancel'),
    cancelAppointment: t('cancel_appointment'),
    deleteAppointment: t('delete_appointment'),
    edit: t('appointment_edit_tooltip'),
    save: t('appointment_edit.form.save'),
    schedule: t('schedule'),
  },
  form: {
    comment: t('appointment_edit.form.comment'),
    duration: {
      errors: {
        required: t('mandatory_fields.duration'),
        min: t('fields.numberMin', { minAmount: MIN_TREATMENT_DURATION }),
        typeError: t('fields.number'),
      },
      label: t('appointment_edit.form.duration'),
    },
    employee: {
      label: t('appointment_edit.form.practitioner'),
      noPreferredEmployee: t('appointment_create.no_preferred_practitioner'),
      error: t('mandatory_fields.practitioner'),
    },
    dentistSelect: {
      label: t('appointment_edit.form.dentist.label'),
      toggleAll: t('appointment_edit.form.dentist.toggleAll'),
      error: t('mandatory_fields.practitioner'),
      all: t('appointment_edit.form.dentist.all'),
    },
    hygenistSelect: {
      label: t('appointment_edit.form.hygienist.label'),
      toggleAll: t('appointment_edit.form.hygienist.toggleAll'),
      error: t('mandatory_fields.practitioner'),
      all: t('appointment_edit.form.hygienist.all'),
    },
    time: {
      beforeNoon: t('datetimepicker.before_noon'),
      evening: t('datetimepicker.evening'),
      label: t('appointment_edit.form.date'),
      loadMore: t('datetimepicker.load_more'),
      noon: t('datetimepicker.noon'),
      noSlots: t('datetimepicker.no_slots'),
      error: t('mandatory_fields.date'),
      treatmentBundleInfo: t('treatment_bundle_info'),
      quickFilterOneYear: t('datetimepicker.year'),
      quickFilterSixMonths: t('datetimepicker.six_months'),
      quickFilterToday: t('today'),
      week: t('week'),
    },
    treatment: {
      categories: {
        TREATMENT: t('treatment'),
        TREATMENT_BUNDLE: t('treatment_bundle'),
      },
      error: t('mandatory_fields.treatment'),
      label: t('appointment_edit.form.treatment'),
    },
    title: {
      error: t('mandatory_fields.title'),
      label: t('appointment_edit.form.title'),
    },
    patient: {
      label: t('appointment_edit_patients'),
      error: t('mandatory_fields.patient'),
      newPatientButton: t('appointment_add_new_patient'),
      form: {
        firstName: {
          error: t('mandatory_fields.firstname'),
          label: t('appointment_edit.form.firstname'),
        },
        lastName: {
          error: t('mandatory_fields.lastname'),
          label: t('appointment_edit.form.lastname'),
        },
        gender: {
          error: t('mandatory_field'),
          label: t('appointment_edit.form.gender'),
        },
        birthDay: {
          error: t('mandatory_field'),
          label: t('appointment_edit.form.birth_day'),
        },
        email: {
          error: t('mandatory_field'),
          label: t('appointment_edit.form.email'),
        },
        insurance: {
          error: t('mandatory_field'),
          label: t('appointment_edit.form.insurance'),
        },
        phoneNumber: {
          error: t('mandatory_fields.phonenumber'),
          label: t('appointment_edit.form.phone_number'),
        },
      },
    },
  },
  title: {
    edit: t('appointment_edit.title'),
    create: t('appointment_create_title'),
    createPatient: t('appointment_add_new_patient'),
    cancellation: t('cancellation.title'),
    createOpenAppointment: t('add_new_open_appointment'),
    editOpenAppointment: t('add_new_open_appointment'),
  },
  pain: {
    questions: {
      'booking-process_pain-treatment_details_question-1': t(
        'appointment_booking-process_pain-treatment_details_question-1'
      ),
      'booking-process_pain-treatment_details_question-2': t(
        'appointment_booking-process_pain-treatment_details_question-2'
      ),
      'booking-process_pain-treatment_details_question-3': t(
        'appointment_booking-process_pain-treatment_details_question-3'
      ),
      'booking-process_pain-treatment_details_question-4': t(
        'appointment_booking-process_pain-treatment_details_question-4'
      ),
      'booking-process_pain-treatment_details_question-5': t(
        'appointment_booking-process_pain-treatment_details_question-5'
      ),
      'booking-process_pain-treatment_details_question-6': t(
        'appointment_booking-process_pain-treatment_details_question-6'
      ),
      'upsell.vitamin_d.hint.question': t('appointment_upsell.vitamin_d.hint.question'),
    },
    answers: {
      'pain_form_answer_1-3': t('appointment_pain_form_answer_1-3'),
      'pain_form_answer_4-7': t('appointment_pain_form_answer_4-7'),
      'pain_form_answer_8-14': t('appointment_pain_form_answer_8-14'),
      pain_form_answer_14: t('appointment_pain_form_answer_14'),
      pain_form_answer_throbbing: t('appointment_pain_form_answer_throbbing'),
      pain_form_answer_pressing: t('appointment_pain_form_answer_pressing'),
      pain_form_answer_pulling: t('appointment_pain_form_answer_pulling'),
      pain_form_answer_stinging: t('appointment_pain_form_answer_stinging'),
      'pain_form_answer_very-light': t('appointment_pain_form_answer_very-light'),
      pain_form_answer_light: t('appointment_pain_form_answer_light'),
      pain_form_answer_medium: t('appointment_pain_form_answer_medium'),
      pain_form_answer_strong: t('appointment_pain_form_answer_strong'),
      'pain_form_answer_very-strong': t('appointment_pain_form_answer_very-strong'),
      pain_form_answer_heat: t('appointment_pain_form_answer_heat'),
      pain_form_answer_cold: t('appointment_pain_form_answer_cold'),
      pain_form_answer_none: t('appointment_pain_form_answer_none'),
      pain_form_answer_day: t('appointment_pain_form_answer_day'),
      pain_form_answer_night: t('appointment_pain_form_answer_night'),
      pain_form_answer_eating: t('appointment_pain_form_answer_eating'),
      pain_form_answer_spontaneously: t('appointment_pain_form_answer_spontaneously'),
      pain_form_answer_always: t('appointment_pain_form_answer_always'),
      pain_form_answer_teeth: t('appointment_pain_form_answer_teeth'),
      pain_form_answer_gums: t('appointment_pain_form_answer_gums'),
      'pain_form_answer_temporomandibular-joint': t('appointment_pain_form_answer_temporomandibular-joint'),
      'pain_form_answer_not-clearly-localizable': t('appointment_pain_form_answer_not-clearly-localizable'),
      'upsell.vitamin_d.hint.answer_yes': t('appointment_upsell.vitamin_d.hint.answer_yes'),
    },
  },
})

export const getBlockerLabels = (t: TFunction): BlockerLabels => ({
  modalTitle: t('blocker.modal_title'),
  buttons: {
    save: t('blocker.save_button'),
    cancel: t('blocker.cancel_button'),
    edit: t('blocker.edit_button'),
    delete: t('blocker.delete_button'),
  },
  form: {
    description: {
      label: t('blocker.form.description'),
      error: t('mandatory_fields.title'),
    },
    employee: {
      label: t('blocker.form.practitioner'),
      noPreferredEmployee: t('blocker.form.no_preferred_practitioner'),
      error: t('mandatory_fields.practitioner'),
    },
    startDate: {
      label: t('blocker.form.start_date'),
      error: t('blocker.form.start_date_error'),
    },
    startTime: t('blocker.form.start_time'),
    endTime: t('blocker.form.end_time'),
    workRelated: t('blocker.form.work_related'),
  },
  deletionDialog: {
    title: t('blocker.delete.title'),
    message: t('blocker.delete.message'),
    messageSuffix: t('blocker.delete.message_suffix'),
    confirmation: t('blocker.delete.confirmation'),
    buttons: {
      save: t('blocker.delete.save'),
      cancel: t('blocker.delete.cancel'),
    },
    dates: {
      from: t('blocker.delete.dates.from'),
      to: t('blocker.delete.dates.to'),
    },
  },
})
